import Narrator3Avatar from '@/../public/_n/images/allyson-pimental.png';
import Narrator1Avatar from '@/../public/_n/images/chibs-okereke.png';
import Narrator2Avatar from '@/../public/_n/images/mathew-goodman.png';

import { Narrator } from './types';

export const narrators: Narrator[] = [
	{
		name: 'Chibs Okereke',
		image: Narrator1Avatar,
		bio: 'Chibs Okereke is a stress and burnout specialist, Mindfulness-Based Stress Reduction (MBSR) facilitator, former company founder and reformed striver. He provides practical tools to help organizations, teams and individuals reduce stress and prevent burnout.',
	},
	{
		name: 'Matthew Goodman, Ph.D.',
		image: Narrator2Avatar,
		bio: ' Matthew Goodman, Ph.D. is a licensed clinical psychologist (PSY32423), consultant, and mindfulness teacher. Matthew has taught mindfulness to children and adults in hospitals, schools, corporate settings, and to the general public for 10+ years. He was previously Clinical Assistant Professor of Psychiatry and the Behavioral Sciences at the Keck School of Medicine of USC, where he also sat on the steering committee for the USC Center for Mindfulness Science. Matthew has authored several peer-reviewed journal articles and book chapters on mindfulness and is the author of the book, “Simple Stress-Reduction: Easy and Effective Practices for Kids, Teens, and Adults.” He is the founder of Zen-prov!, an improv class utilizing play as a means to practicing mindfulness, self-compassion, self-confidence, and other key life skills. Matthew provides consultation to organizations on team dynamics, leadership, and wellness and writes a “Conscious Business” column for Inc. Magazine. His mission is to highlight our interconnectedness.',
	},
	{
		name: 'Allyson Pimental',
		image: Narrator3Avatar,
		bio: 'Dr. Allyson Pimentel is a psychologist and long-time student and teacher in the Insight Meditation tradition, working at the intersection of mindfulness, mental health, and social justice. Allyson holds a BA from Brown University and a doctorate from Harvard University. She is the Co-Director of Mindful USC at the University of Southern California and also teaches at the UCLA Mindful Awareness Research Center (MARC) and at InsightLA. Her writings on mindfulness have appeared in Lion’s Roar magazine and elsewhere. Allyson loves sharing mindfulness as a pathway for individual and collective healing, wisdom, and freedom.',
	},
];
