import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, IconButton, Text, TextButton, minWidth } from '@calm-web/design-system';
import { RightArrow } from '@calm-web/icons';

export const Wrapper = styled.section`
	display: grid;
	position: relative;
	z-index: 0;

	${minWidth('tablet')} {
		grid-template-columns: 50% 50%;
		min-height: calc(100vh - 64px);
	}

	${minWidth('desktop')} {
		min-height: calc(100vh - 100px);
	}

	&:before {
		background: ${palette('bannerText')};
		content: '';
		inset: 0;
		opacity: 0.1;
		position: absolute;
		z-index: -1;
	}
`;

export const DownArrow = styled(IconButton)`
	&&& {
		height: 64px;

		${minWidth('tablet')} {
			bottom: 0;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
		}

		& > div {
			height: 20px;
			width: 36px;
		}

		& svg {
			height: 20px;
			width: 36px;
		}
	}
`;

export const Copy = styled.div`
	color: white;
	position: relative;

	background: linear-gradient(222.53deg, #c8e7ff -1.39%, rgba(84, 132, 213, 0) 45.94%),
		linear-gradient(33.9deg, #bb91e9 -2.86%, rgba(187, 145, 233, 0) 47.43%),
		linear-gradient(107.56deg, #4667c4 0%, #5286d7 49.48%, #7193df 100%);

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1rem;

	${minWidth('tablet')} {
		padding: 5rem;
	}
`;

export const CopyInner = styled.div`
	max-width: min(100vw, 441px);
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Heading = styled(Text).attrs({
	el: 'h1',
	weight: 700,
})`
	font-size: 34px;
	line-height: 42px;

	${minWidth('tablet')} {
		font-size: 3rem;
		line-height: 3.5rem;
	}
`;

export const Body = styled(Text)`
	margin-top: 1.5rem;
	font-size: 16px;

	${minWidth('tablet')} {
		font-size: 18px;
	}
`;

export const InfoDialogTriggerButton = styled(TextButton)`
	color: inherit !important;
`;

export const Disclaimer = styled(Text).attrs({ weight: 700, el: 'p' })`
	margin-top: 1.5rem;
	font-size: 0.75rem;
`;

export const GetStartedButton = styled(Button)`
	margin-top: 2.5rem;
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const GetStartedArrow = styled(RightArrow)`
	margin-left: 1rem;

	& path {
		fill: #000000cc;
	}
`;

export const VideoContainer = styled.div`
	position: relative;
	overflow: hidden;
`;

export const Video = styled.video`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const PlayPauseButtonContainer = styled.div`
	position: absolute;
	bottom: 2rem;
	right: 2rem;
`;
export const PlayPauseButton = styled(IconButton)`
	background: #00000099;
	width: 48px;
	height: 48px;
`;
