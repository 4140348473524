/* eslint-disable max-len */
import Script from 'next/script';

const LayoutScripts = (): JSX.Element => {
	return (
		<Script id="browser-warning-init">
			{`
          var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
          // true on IE11
          // false on Edge and other IEs/browsers.
          if (isIE11) {
            window.location = '/_n/browserwarning.html?browser=ie11';
          }
        `}
		</Script>
	);
};

export default LayoutScripts;
