import Disclaimer from './disclaimer';
import Hero from './hero';
import InfoPods from './info-pods';
import Narrators from './narrators';
import Statistics from './statistics';
import { Wrapper, Content } from './styles';

const Screening = (): JSX.Element => {
	return (
		<Wrapper>
			<Hero />
			<Content>
				<InfoPods />
				<Statistics />
				<Narrators />
				<Disclaimer />
			</Content>
		</Wrapper>
	);
};

export default Screening;
