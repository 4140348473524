import * as amplitude from '@amplitude/analytics-browser';
import MobileDetect from 'mobile-detect';

import { EventProps } from '.';

export type LogEventResult = amplitude.Types.Result;
export async function initAmplitude(): Promise<void> {
	if (!process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY) {
		// eslint-disable-next-line no-console
		console.warn('NEXT_PUBLIC_AMPLITUDE_API_KEY not defined.. expect errors below');
		return;
	}

	await amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, undefined, {
		defaultTracking: true,
		cookieOptions: {
			domain: 'calm.com',
		},
	}).promise;
}

export async function logAmplitudeEvent({
	eventName,
	eventProps,
}: {
	eventName: string;
	eventProps?: EventProps;
}): Promise<amplitude.Types.Result> {
	const res = await amplitude.track(eventName, eventProps).promise;
	return res;
}

export function setAmplitudeDeviceProperties(): void {
	if (typeof window === 'undefined') {
		return;
	}

	const md = new MobileDetect(window.navigator.userAgent);
	const deviceType = md.mobile() ? 'mobile' : 'desktop';

	const identifyObj = new amplitude.Identify();
	identifyObj.set('web_device_type', deviceType);
	amplitude.identify(identifyObj);
}
