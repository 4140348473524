// These are our supported languages
export const CALM_LANGUAGES = ['en', 'fr', 'de', 'es', 'ja', 'ko', 'pt'] as const;
export type CalmLanguage = (typeof CALM_LANGUAGES)[number];
const isCalmLanguage = (value: string): value is CalmLanguage =>
	CALM_LANGUAGES.includes(value as CalmLanguage);

export const getCalmLanguage = (string: string): CalmLanguage => {
	try {
		const firstSegment = string.toLowerCase().split('-')[0];
		if (isCalmLanguage(firstSegment)) {
			return firstSegment;
		}
		return 'en';
	} catch (err) {
		return 'en';
	}
};
