import Image from 'next/legacy/image';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { Text, minWidth } from '@calm-web/design-system';

export const Wrapper = styled.div`
	max-width: ${theme('breakpoints.maxWidth')}px;
	margin: 6rem auto 0;
`;

export const Title = styled(Text).attrs({ el: 'h2', align: 'center' })`
	color: #00315b;
`;

export const StatisticsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 2.5rem;

	${minWidth('tablet')} {
		flex-direction: row;
	}
`;

export const Item = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	padding: 0 1rem;

	${minWidth('tablet')} {
		flex-direction: column;
	}

	&:nth-of-type(n + 1) {
		margin-top: 0.5rem;

		${minWidth('tablet')} {
			margin-top: 0;
		}
	}
`;

export const ItemCopy = styled.div`
	padding-left: 1rem;
	text-align: left;

	color: #1a1a2c;

	${minWidth('tablet')} {
		text-align: center;
		padding-left: 0;
	}
`;

export const ImageContainer = styled.div`
	flex-shrink: 0;

	width: 100px;
	height: 100px;
	${minWidth('tablet')} {
		height: 200px;
		width: 200px;
	}
`;

export const ItemImage = styled(Image)`
	${minWidth('tablet')} {
		margin-top: 1.5rem;
	}
`;

export const ItemTitle = styled(Text).attrs({ weight: 500, el: 'p' })`
	font-weight: 500;
	font-size: 16px;

	${minWidth('desktop')} {
		font-size: 24px;
	}
`;

export const ItemBody = styled(Text)`
	margin-top: 0.5rem;

	font-size: 14px;

	${minWidth('desktop')} {
		font-size: 18px;
	}
`;

export const Source = styled.a`
	display: block;
	text-align: center;
	margin-top: 4rem;
	color: #1a1a2cb2;
`;
