import { useState } from 'react';
import { useIntl } from 'react-intl';

import { narrators } from './data';
import Dialog from './dialog';
import messages from './messages';
import {
	Avatar,
	AvatarContainer,
	MeetButton,
	NarratorsContainer,
	Wrapper,
	Title,
	ItemTitle,
	ItemSubtitle,
	Item,
	Arrow,
} from './styles';
import { Narrator } from './types';

const getFirstName = (name: string): string | undefined => name.split(' ')[0];

const Narrators = (): JSX.Element => {
	const { formatMessage } = useIntl();

	const [selectedNarrator, setSelectedNarrator] = useState<Narrator | undefined>(undefined);
	return (
		<Wrapper>
			<Title>{formatMessage(messages.title)}</Title>
			<NarratorsContainer>
				{narrators.map(narrator => (
					<Item key={narrator.name}>
						<AvatarContainer>
							<Avatar src={narrator.image} alt="" />
						</AvatarContainer>
						<ItemTitle>{narrator.name}</ItemTitle>
						<ItemSubtitle>{formatMessage(messages.narrator)}</ItemSubtitle>
						<MeetButton
							onPress={() => setSelectedNarrator(narrator)}
							backgroundColor="transparentWhiteBackground"
							size="md"
						>
							{formatMessage(messages.meet, { name: getFirstName(narrator.name) })}
							<Arrow />
						</MeetButton>
					</Item>
				))}
			</NarratorsContainer>
			<Dialog narrator={selectedNarrator} onClose={() => setSelectedNarrator(undefined)} />
		</Wrapper>
	);
};

export default Narrators;
