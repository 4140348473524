import { useIntl } from 'react-intl';

import InfoDialog from '..';
import ScaleExplanation from '../scale-explanation';
import { gadGroups } from '../scale-explanation/data';
import { Section, SectionParagraph, SectionTitle } from '../styles';
import { InfoDialogProps } from '../types';
import messages from './messages';

const GadInfoDialog = ({ isOpen, onClose }: InfoDialogProps): JSX.Element => {
	const { formatMessage } = useIntl();

	return (
		<InfoDialog title={formatMessage(messages.title)} isOpen={isOpen} closeModal={onClose}>
			<Section>
				<SectionTitle>{formatMessage(messages.section1Title)}</SectionTitle>
				<SectionParagraph>{formatMessage(messages.section1Body)}</SectionParagraph>
			</Section>
			<Section>
				<SectionTitle>{formatMessage(messages.section2Title)}</SectionTitle>
				<SectionParagraph>{formatMessage(messages.section2Body)}</SectionParagraph>
			</Section>
			<Section>
				<ScaleExplanation groups={gadGroups} />
			</Section>
			<Section>
				<SectionParagraph>{formatMessage(messages.section3Body1)}</SectionParagraph>
				<SectionParagraph>{formatMessage(messages.section3Body2)}</SectionParagraph>
			</Section>
		</InfoDialog>
	);
};

export default GadInfoDialog;
