import { useIntl } from 'react-intl';

import { statistics } from './data';
import messages from './messages';
import {
	StatisticsContainer,
	Wrapper,
	Title,
	Source,
	ItemTitle,
	ItemBody,
	Item,
	ItemCopy,
	ItemImage,
	ImageContainer,
} from './styles';

const Statistics = (): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		<Wrapper>
			<Title>{formatMessage(messages.title)}</Title>
			<StatisticsContainer>
				{statistics.map(({ title, body, image }) => (
					<Item key={title.id}>
						<ImageContainer>
							<ItemImage src={image} width={200} height={200} />
						</ImageContainer>
						<ItemCopy>
							<ItemTitle>{formatMessage(title)}</ItemTitle>
							<ItemBody>{formatMessage(body)}</ItemBody>
						</ItemCopy>
					</Item>
				))}
			</StatisticsContainer>
			<Source href="https://www.nami.org/mhstats" target="_blank">
				{formatMessage(messages.source)}
			</Source>
		</Wrapper>
	);
};

export default Statistics;
