import { defineMessages } from 'react-intl';

export default defineMessages({
	minimal: {
		id: 'screening.scaleExplanation.minimal',
		defaultMessage: 'Minimal 0-4',
		description: '',
	},
	mild: {
		id: 'screening.scaleExplanation.mild',
		defaultMessage: 'Mild 5-9',
		description: '',
	},
	moderate: {
		id: 'screening.scaleExplanation.moderate',
		defaultMessage: 'Moderate 10-14',
		description: '',
	},
	severe: {
		id: 'screening.scaleExplanation.severe',
		defaultMessage: 'Severe 15-21',
		description: '',
	},
	moderateSevere: {
		id: 'screening.scaleExplanation.moderateSevere',
		defaultMessage: 'Moderately Severe 15-19',
		description: '',
	},
	severe2: {
		id: 'screening.scaleExplanation.severe2',
		defaultMessage: 'Severe 20-27',
		description: '',
	},
});
