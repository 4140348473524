import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'screening.phq.title',
		defaultMessage: 'PHQ-8',
		description: '',
	},
	section1Title: {
		id: 'screening.phq.section1Title',
		defaultMessage: 'What is the PHQ-8 Depression screening?',
		description: '',
	},
	section1Body: {
		id: 'screening.phq.section1Body',
		defaultMessage:
			'The PHQ-8 Depression screening helps identify signs of depression by asking about your feelings and experiences over the past two weeks. It focuses on eight questions that relate to common symptoms of depression, such as feeling down, having trouble sleeping, or losing interest in activities you usually enjoy.',
		description: '',
	},
	section2Title: {
		id: 'screening.phq.section2Title',
		defaultMessage: 'How the PHQ-8 works',
		description: '',
	},
	section2Body1: {
		id: 'screening.phq.section2Body1',
		defaultMessage:
			'If you’re feeling down, taking the PHQ-8 screening is a proactive way to learn more about your mental health. The questions in the PHQ-8 ask about the most common symptoms that people with depression often experience, such as feeling hopeless, having little energy, or experiencing changes in your appetite.',
		description: '',
	},
	section2Body2: {
		id: 'screening.phq.section2Body2',
		defaultMessage:
			"Each answer is scored to give an overall picture of your mood and behavior. The total score helps indicate whether you might be experiencing depressive symptoms and how severe they might be. Your score isn't a diagnosis, but it can show whether your symptoms are likely mild, moderate, or severe. This can guide you on what steps to take next, whether it's monitoring your feelings or talking to someone you trust.",
		description: '',
	},
	section2Body3: {
		id: 'screening.phq.section2Body3',
		defaultMessage:
			'If your PHQ-8 score suggests you might be dealing with depressive symptoms, talk to a healthcare provider for a full evaluation. They can discuss your results and work with you to decide the best course of action.',
		description: '',
	},
});
