import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { FocusRingVariants } from '@calm-web/design-system';
import { ChevronDown, Pause, PlayOutline } from '@calm-web/icons';

import { useAnalytics } from '@/hooks/useAnalytics';

import GadInfoDialog from './info-dialog/gad';
import PhqInfoDialog from './info-dialog/phq';
import messages from './messages';
import {
	Wrapper,
	Copy,
	Heading,
	Body,
	Disclaimer,
	GetStartedButton,
	DownArrow,
	VideoContainer,
	Video,
	PlayPauseButton,
	InfoDialogTriggerButton,
	PlayPauseButtonContainer,
	GetStartedArrow,
	CopyInner,
} from './styles';
import { InfoDialog } from './types';

const Hero = (): JSX.Element => {
	const { formatMessage } = useIntl();
	const [isVideoPlaying, setVideoPlaying] = useState(true);
	const [openInfoDialog, _setOpenInfoDialog] = useState<InfoDialog | undefined>(undefined);

	const videoRef = useRef<HTMLVideoElement>(null);

	const onDownArrowClick = (): void => {
		window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
	};

	const togglePlayVideo = (): void => {
		if (!videoRef.current) {
			return;
		}

		if (isVideoPlaying) {
			videoRef.current.pause();
			return;
		}
		videoRef.current.play().catch(() => {
			/* swallow exception */
		});
	};

	useEffect(() => {
		const video = videoRef.current;
		const setPlaying = (): void => setVideoPlaying(true);
		const setPaused = (): void => setVideoPlaying(false);

		video?.addEventListener('play', setPlaying);
		video?.addEventListener('pause', setPaused);

		return () => {
			video?.removeEventListener('play', setPlaying);
			video?.removeEventListener('pause', setPaused);
		};
	}, []);

	const { logEvent } = useAnalytics();
	const onPressHeroCta = useCallback((): void => {
		logEvent({ eventName: 'Screening : Clicked Get Started' });
	}, [logEvent]);
	const setOpenInfoDialog = useCallback(
		(dialog: InfoDialog | undefined): void => {
			_setOpenInfoDialog(dialog);
			if (dialog) {
				logEvent({ eventName: 'Screening : Opened Info Dialog', eventProps: { dialog } });
			}
		},
		[logEvent],
	);

	return (
		<Wrapper>
			<Copy>
				<CopyInner>
					<Heading>{formatMessage(messages.heroHeading)}</Heading>
					<Body>
						{formatMessage(messages.heroBody, {
							gadbutton: (chunks: ReactNode) => (
								<InfoDialogTriggerButton onClick={() => setOpenInfoDialog(InfoDialog.gad)}>
									{chunks}
								</InfoDialogTriggerButton>
							),
							phqbutton: (chunks: ReactNode) => (
								<InfoDialogTriggerButton onClick={() => setOpenInfoDialog(InfoDialog.phq)}>
									{chunks}
								</InfoDialogTriggerButton>
							),
						})}
					</Body>
					<Disclaimer>{formatMessage(messages.heroDisclaimer)}</Disclaimer>
					<GetStartedButton
						textColor="black"
						backgroundColor="white"
						onPress={onPressHeroCta}
						href="https://app.calmhealth.com/mental-health-screening"
						openInNewTab
					>
						{formatMessage(messages.heroCta)} <GetStartedArrow />
					</GetStartedButton>

					<DownArrow
						onPress={onDownArrowClick}
						color="white"
						aria-label={formatMessage(messages.buttonArrowDownLabel)}
						Icon={ChevronDown}
						focusRingVariant={FocusRingVariants.Dark}
					/>
				</CopyInner>
			</Copy>
			<VideoContainer>
				<Video src="/_n/videos/screening-loop.mp4" autoPlay loop playsInline ref={videoRef} muted />
				<PlayPauseButtonContainer>
					<PlayPauseButton
						Icon={isVideoPlaying ? Pause : PlayOutline}
						onPress={togglePlayVideo}
						color="white"
						aria-label={formatMessage(isVideoPlaying ? messages.pauseVideo : messages.playVideo)}
					/>
				</PlayPauseButtonContainer>
			</VideoContainer>
			<PhqInfoDialog
				isOpen={openInfoDialog === InfoDialog.phq}
				onClose={() => setOpenInfoDialog(undefined)}
			/>
			<GadInfoDialog
				isOpen={openInfoDialog === InfoDialog.gad}
				onClose={() => setOpenInfoDialog(undefined)}
			/>
		</Wrapper>
	);
};

export default Hero;
