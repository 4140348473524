import { defineMessages } from 'react-intl';

export default defineMessages({
	disclaimer: {
		id: 'screening.disclaimer.legal',
		defaultMessage:
			'This screening, and the use of Calm Health, is not intended to diagnose or treat depression, anxiety, or any other disease or condition. This screening, and the use of Calm Health, is not a substitute for care by a physician or other health care provider. Any questions that you may have regarding the results or  the diagnosis, care, or treatment of a medical condition should be directed to your physician or health care provider. Calm Health is a mental wellness product.',
		description: '',
	},
});
