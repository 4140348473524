import { MessageDescriptor, useIntl } from 'react-intl';

import { Dot, Explanation, Item, Wrapper } from './styles';

interface Props {
	groups: { color: string; label: MessageDescriptor }[];
}

const ScaleExplanation = ({ groups }: Props): JSX.Element => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			{groups.map(({ color, label }) => (
				<Item key={color}>
					<Dot color={color}></Dot>
					<Explanation>{formatMessage(label)}</Explanation>
				</Item>
			))}
		</Wrapper>
	);
};

export default ScaleExplanation;
