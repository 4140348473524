import styled from 'styled-components';

import { Modal } from '@calm-web/design-system';

export const Dialog = styled(Modal)`
	& > div {
		border-radius: 0;
	}
`;

export const Content = styled.div`
	height: 100dvh;
	overflow-y: auto;
	background: linear-gradient(rgb(226, 246, 255) 0%, rgb(253, 247, 249) 100%),
		linear-gradient(0deg, rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0.23));
`;

export const InnerContent = styled.div`
	padding: 5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: min(95vw, 600px);
	margin: auto;
	color: #1a1a2cb2;
`;
