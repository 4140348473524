import { useIntl } from 'react-intl';

import { Hr } from '@calm-web/design-system';

import FullScreenDialog from '../../full-screen-dialog';
import screeningMessages from '../messages';
import { Narrator } from '../types';
import messages from './messages';
import { Avatar, Bio, Name, Subtitle, DividerContainer } from './styles';

interface Props {
	narrator?: Narrator;
	onClose: () => void;
}
const NarratorDialog = ({ narrator, onClose }: Props): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		<FullScreenDialog
			isOpen={!!narrator}
			closeModal={onClose}
			aria-label={formatMessage(messages.dialogTitle, { name: narrator?.name })}
		>
			{narrator && (
				<>
					<Avatar src={narrator.image} height={364} width={364} />
					<Name>{narrator.name}</Name>
					<Subtitle>{formatMessage(screeningMessages.narrator)}</Subtitle>
					<DividerContainer>
						<Hr opacity={0.1} />
					</DividerContainer>
					<Bio>{narrator.bio}</Bio>
				</>
			)}
		</FullScreenDialog>
	);
};

export default NarratorDialog;
