import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { CALM_LANGUAGES, CalmLanguage } from '@/utils/language';

import { useRouterPush } from './useRouterPush';

const sliceLanguageString = (lang: string): CalmLanguage => lang.slice(0, 2) as CalmLanguage;

export const useLocaleRedirect = (): void => {
	const { locale: routerLanguage, query, pathname } = useRouter();
	const browserLanguage = typeof window !== 'undefined' ? window.navigator.language : undefined;
	const routerPush = useRouterPush();

	const onPush = async (newLanguage: CalmLanguage | undefined): Promise<void> => {
		if (!query.ignore_locale && typeof newLanguage !== 'undefined' && CALM_LANGUAGES.includes(newLanguage)) {
			await routerPush(pathname, undefined, {
				locale: newLanguage,
				shallow: true,
			});
		}
	};

	const redirectToPreferredLocale = async (): Promise<void> => {
		if (!browserLanguage) {
			return;
		}
		const slicedBrowserLang = sliceLanguageString(browserLanguage);
		if (slicedBrowserLang !== routerLanguage) {
			await onPush(slicedBrowserLang);
		}
	};

	useEffect(
		() => {
			// eslint-disable-next-line no-console
			redirectToPreferredLocale().catch(error => console.error('Error in useLocaleRedirect', {}, error));
		}, // TODO [WEB-1595]: Fix this exhaustive-deps issue
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[routerLanguage, browserLanguage],
	);
};
