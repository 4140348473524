// Third Party
import { defineMessages } from 'react-intl';

export default defineMessages({
	heroHeading: {
		id: 'screening.hero.heading',
		defaultMessage: 'How are you feeling right now?',
		description: '',
	},
	heroBody: {
		id: 'screening.hero.body',
		defaultMessage:
			'Answering a few simple questions can tell you a lot about yourself. Take a quick mental health screening to get a snapshot of your current state of mind, as well as some recommendations for next steps. The screening is a combined version of the Generalized Anxiety Disorder 7 (<gadbutton>GAD-7</gadbutton>) and Patient Health Questionnaire 8 (<phqbutton>PHQ-8</phqbutton>). This screening is not a substitute for care by a physician or other health care provider.',
		description: '',
	},
	heroDisclaimer: {
		id: 'screening.hero.disclaimer',
		defaultMessage:
			'Please note that at this time, the mental health screening is available only to U.S. residents age 18 and older.',
		description: '',
	},
	heroCta: {
		id: 'screening.hero.cta',
		defaultMessage: 'Get Started',
		description: '',
	},
	buttonArrowDownLabel: {
		id: 'screening.hero.buttonArrowDownLabel',
		defaultMessage: 'Scroll to next section',
		description: 'Alt text for button that scrolls user to next section',
	},
	pauseVideo: {
		id: 'screening.hero.pauseVideo',
		defaultMessage: 'Pause video',
		description: '',
	},
	playVideo: {
		id: 'screening.hero.playVideo',
		defaultMessage: 'Play video',
		description: '',
	},
});
