import styled from 'styled-components';

import { Text } from '@calm-web/design-system';

interface DotProps {
	color: string;
}

export const Wrapper = styled.div`
	align-self: flex-start;
`;

export const Dot = styled.div<DotProps>`
	background-color: ${({ color }) => color};
	width: 13px;
	height: 13px;
	border-radius: 50%;
	border: 1px solid rgba(0, 0, 0, 0.38);
`;

export const Item = styled.div`
	display: flex;
	align-items: center;

	&:nth-of-type(n + 2) {
		margin-top: 0.75rem;
	}
`;

export const Explanation = styled(Text).attrs({ el: 'span' })`
	margin-left: 1rem;
`;
