import Statistic1Icon from '@/../public/_n/images/statistic-1.png';
import Statistic2Icon from '@/../public/_n/images/statistic-2.png';
import Statistic3Icon from '@/../public/_n/images/statistic-3.png';

import messages from './messages';

export const statistics = [
	{ title: messages.statistic1Title, body: messages.statistic1Body, image: Statistic1Icon },
	{ title: messages.statistic2Title, body: messages.statistic2Body, image: Statistic2Icon },
	{ title: messages.statistic3Title, body: messages.statistic3Body, image: Statistic3Icon },
];
