import { useIntl } from 'react-intl';

import { Wrapper } from './styles';
import { HeaderNavItem } from './types';

const NavItemLink = ({
	onClick,
	navItem,
	isOpaque,
	ariaLabel,
	testId,
}: {
	navItem: HeaderNavItem;
	onClick: () => void;
	isOpaque: boolean;
	ariaLabel?: string;
	testId?: string;
}): JSX.Element => {
	const { formatMessage } = useIntl();

	const textColor = isOpaque ? 'gray7' : 'white';

	return (
		<Wrapper data-testid={testId} onClick={onClick} textColor={textColor} aria-label={ariaLabel}>
			{formatMessage(navItem.label)}
		</Wrapper>
	);
};

export default NavItemLink;
