import Image from 'next/legacy/image';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { Button, Text, minWidth } from '@calm-web/design-system';
import { RightArrow } from '@calm-web/icons';

export const Wrapper = styled.div`
	max-width: ${theme('breakpoints.maxWidth')}px;
	margin: 6rem auto 0;
`;

export const Title = styled(Text).attrs({ el: 'h2', align: 'center' })`
	color: #00315b;
`;

export const NarratorsContainer = styled.div`
	margin-top: 5rem;
	margin: 4rem auto 0;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 1.5rem;

	${minWidth('tablet')} {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const Item = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: 364px;
	margin: 0 auto;
`;

export const AvatarContainer = styled.div`
	width: 164px;
	height: 164px;
	margin: auto;
`;

export const Avatar = styled(Image)`
	border-radius: 50%;
`;

export const ItemTitle = styled(Text).attrs({ align: 'center', weight: 500 })`
	margin-top: 1rem;
	font-size: 16px;

	min-height: 48px;
	max-width: 164px;

	${minWidth('tablet')} {
		min-height: auto;
		font-size: 24px;
		max-width: unset;
		margin-top: 1.5rem;
	}
`;

export const ItemSubtitle = styled(Text).attrs({ align: 'center', el: 'p' })`
	color: #1a1a2c;

	font-size: 14px;

	${minWidth('desktop')} {
		font-size: 18px;
	}
`;

export const ItemBody = styled(Text).attrs({ align: 'center' })`
	text-align: center;
`;

export const Source = styled.a`
	display: block;
	text-align: center;
	margin-top: 8rem;
`;

export const MeetButton = styled(Button)`
	margin: 1rem auto 0;
	color: #0060a9 !important;
	border: 1px solid #0060a9 !important;
	font-weight: 700;
	display:flex;
	justify-content:center;
	width: 164px;

	padding: 4px 10px;

	${minWidth('desktop')} {
		width:240px;
		padding: 8px 22px;
		margin: 1.5rem auto 0;
	}

	&:hover {
		background: #0060a90a;!important
	}
`;

export const Arrow = styled(RightArrow)`
	margin-left: 0.5rem;

	${minWidth('tablet')} {
		margin-left: 1rem;
	}
	& path {
		fill: #0060a9;
	}
`;
