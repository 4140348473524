import { useIntl } from 'react-intl';

import messages from './messages';
import { LegalText, Wrapper } from './styles';

const Disclaimer = (): JSX.Element => {
	const { formatMessage } = useIntl();

	return (
		<Wrapper>
			<LegalText>{formatMessage(messages.disclaimer)}</LegalText>
		</Wrapper>
	);
};

export default Disclaimer;
