import Image from 'next/legacy/image';
import styled from 'styled-components';

import { Text } from '@calm-web/design-system';

export const Avatar = styled(Image)`
	border-radius: 50%;
`;

export const Name = styled(Text).attrs({ align: 'center', weight: 700 })`
	margin-top: 2rem;
`;

export const Subtitle = styled(Text).attrs({ align: 'center' })`
	margin-top: 0.5rem;
	margin-bottom: 1rem;
`;

export const Bio = styled(Text)`
	margin-top: 2rem;
`;

export const DividerContainer = styled.div`
	width: 100%;
`;
