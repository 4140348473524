import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'screening.statistics.title',
		defaultMessage: 'Did you know?',
		description: '',
	},
	source: {
		id: 'screening.statistics.source',
		defaultMessage: 'Mental Health By the Numbers | NAMI: National Alliance on Mental Illness',
		description: '',
	},
	statistic1Title: {
		id: 'screening.statistics.1.title',
		defaultMessage: '1 in 2 Adults',
		description: '',
	},
	statistic1Body: {
		id: 'screening.statistics.1.body',
		defaultMessage: 'Will experience a mental health issue in their lifetime.',
		description: '',
	},
	statistic2Title: {
		id: 'screening.statistics.2.title',
		defaultMessage: '50% of Americans',
		description: '',
	},
	statistic2Body: {
		id: 'screening.statistics.2.body',
		defaultMessage:
			'Will meet the criteria for a diagnosable mental health condition some time in their life.',
		description: '',
	},
	statistic3Title: {
		id: 'screening.statistics.3.title',
		defaultMessage: '264 Million',
		description: '',
	},
	statistic3Body: {
		id: 'screening.statistics.3.body',
		defaultMessage: 'People suffering from depression worldwide.',
		description: '',
	},
});
