import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import SkipToMain, { MAIN_CONTENT_ID } from '@/components/accessibility/SkipToMain';
import Footer from '@/components/footer/FooterWrapper';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useLocaleRedirect } from '@/hooks/utils/useLocaleRedirect';

// import { useAppLoadedState } from '@/hooks/store';
// import initIntersectionObserver from '@/utils/initIntersectionObserver';

import LayoutScripts from '../LayoutScripts';
import { LayoutProps, SeoProps } from './types';
import { getIsMobileClient } from './utils';

const Layout = ({
	children,
	pageName,
	title = 'Calm Mental Health Screening',
	showFooter = false,
	footerProps = undefined,
	customMainId = `#${MAIN_CONTENT_ID}`,
}: LayoutProps): JSX.Element => {
	const router = useRouter();
	// const appLoaded = useAppLoadedState();

	const { logEvent } = useAnalytics();
	useEffect(() => {
		logEvent({
			eventName: 'Page : Viewed',
			eventProps: {
				page_name: pageName,
			},
		});
	}, [logEvent, pageName]);

	const seo = useMemo((): SeoProps => {
		return {
			title: 'Calm Mental Health Screening',
			primaryDescription:
				'Answering a few simple questions can tell you a lot about yourself. Take a quick mental health screening to get a snapshot of your current state of mind, as well as some recommendations for next steps.',
			description:
				'Answering a few simple questions can tell you a lot about yourself. Take a quick mental health screening to get a snapshot of your current state of mind, as well as some recommendations for next steps.',
			imageUrl: 'https://business.calm.com/wp-content/uploads/2024/05/calm-screening-1200x627-1.png',
			url: `https://screening.calm.com${router.asPath}`,
		};
	}, [router.asPath]);

	useLocaleRedirect();

	// useEffect(() => {
	// 	initIntersectionObserver();
	// }, [appLoaded, router.pathname]);

	const isMobileClient = getIsMobileClient(router.query);
	const showSkipToMain = !isMobileClient;

	return (
		<>
			<Head>
				<meta name="Description" content={seo.primaryDescription} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={seo.title} />
				<meta property="og:image" content={seo.imageUrl} />
				<meta property="og:image:alt" content="Calm offers mental health screening" />
				<meta property="og:url" content={seo.url} />
				<meta property="og:description" content={seo.description} />
				<meta property="twitter:site" content="@calm" />
				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:title" content={seo.title} />
				<meta property="twitter:image" content={seo.imageUrl} />
				<meta property="twitter:description" content={seo.description} />
				<title>{title}</title>
			</Head>
			<LayoutScripts />
			{showSkipToMain && <SkipToMain href={customMainId} />}
			{children}
			{showFooter && <Footer {...footerProps} />}
		</>
	);
};

export default Layout;
