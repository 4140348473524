import { Dispatch, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import FlyoutMenu from '@/components/header/FlyoutMenu';
import { leftNavItems, rightNavItems } from '@/components/homepageRefresh/header/NavItems/data';

import NavItemLink from '../NavItemLink';
import { HeaderNavItem } from '../NavItemLink/types';
import { MobileNavWrapper } from './styles';

const MobileMenu = ({
	isMobileMenuOpen,
	setIsMobileMenuOpen,
	onNavItemClick,
}: {
	isMobileMenuOpen: boolean;
	setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
	onNavItemClick: (navItem: HeaderNavItem) => Promise<void>;
}): JSX.Element => {
	const { formatMessage } = useIntl();

	const mobileNavItems: HeaderNavItem[] = [...leftNavItems, ...rightNavItems];

	const onMobileLinkClick = async (navItem: HeaderNavItem): Promise<void> => {
		setIsMobileMenuOpen(false);
		await onNavItemClick(navItem);
	};

	return (
		<FlyoutMenu isOpen={isMobileMenuOpen} label="Mobile navigation menu">
			<MobileNavWrapper>
				{mobileNavItems.map(navItem => (
					<li key={formatMessage(navItem.label)}>
						<NavItemLink navItem={navItem} onClick={() => onMobileLinkClick(navItem)} isOpaque={false} />
					</li>
				))}
			</MobileNavWrapper>
		</FlyoutMenu>
	);
};

export default MobileMenu;
