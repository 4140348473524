import { useState } from 'react';
import { useIntl } from 'react-intl';

import { FocusRingVariants } from '@calm-web/design-system';
import { AppIcon, CalmLogo, CalmLogoGradient } from '@calm-web/icons';

import HamburgerMenu from '@/components/header/HamburgerMenu';

import MobileMenu from '../MobileMenu';
import NavItemLink from '../NavItemLink';
import { HeaderNavItem } from '../NavItemLink/types';
import { leftNavItems, rightNavItems } from './data';
import _messages from './messages';
import {
	Wrapper,
	NavWrapper,
	LogoWrapper,
	IconWrapper,
	NavItemListElement,
	HambergMenuWrapper,
	LogoLink,
	FocusWrapper,
} from './styles';

const NavItems = ({
	isOpaque,
	onNavItemClick,
}: {
	isOpaque: boolean;
	onNavItemClick: (navItem: HeaderNavItem) => Promise<void>;
}): JSX.Element => {
	const { formatMessage } = useIntl();
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const onMenuToggle = (): void => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	return (
		<FocusWrapper disabled={!isMobileMenuOpen}>
			<Wrapper aria-label="primary">
				<NavWrapper>
					<LogoWrapper>
						<LogoLink
							hideShadow
							href="https://www.calm.com/"
							backgroundColor="transparent"
							aria-label={formatMessage(_messages.logoAriaLabel)}
							tabIndex={isMobileMenuOpen ? -1 : 0}
							focusRingVariant={isOpaque ? FocusRingVariants.Dark : FocusRingVariants.Light}
						>
							<IconWrapper $isVisible={!isOpaque} $screenSize="desktop" $width={56} $height={56}>
								<AppIcon width={56} height={56} />
							</IconWrapper>
							<IconWrapper $isVisible={isOpaque}>
								<CalmLogoGradient />
							</IconWrapper>
							<IconWrapper $isVisible={!isOpaque} $screenSize="mobile">
								<CalmLogo />
							</IconWrapper>
						</LogoLink>
					</LogoWrapper>
					{leftNavItems.map(navItem => (
						<NavItemListElement key={navItem.href}>
							<NavItemLink isOpaque={isOpaque} onClick={() => onNavItemClick(navItem)} navItem={navItem} />
						</NavItemListElement>
					))}
				</NavWrapper>
				<NavWrapper>
					{rightNavItems.map(navItem => (
						<NavItemListElement key={navItem.href}>
							<NavItemLink isOpaque={isOpaque} onClick={() => onNavItemClick(navItem)} navItem={navItem} />
						</NavItemListElement>
					))}

					<HambergMenuWrapper>
						<HamburgerMenu onClick={onMenuToggle} isDark={!isOpaque} isOpen={isMobileMenuOpen} />
					</HambergMenuWrapper>
				</NavWrapper>
			</Wrapper>
			<MobileMenu
				isMobileMenuOpen={isMobileMenuOpen}
				setIsMobileMenuOpen={setIsMobileMenuOpen}
				onNavItemClick={onNavItemClick}
			/>
		</FocusWrapper>
	);
};

export default NavItems;
