import { useRouterPush } from '@/hooks/utils/useRouterPush';

import { HeaderNavItem } from '../NavItemLink/types';
import NavItems from '../NavItems';
import { Wrapper } from './styles';

const Header = (): JSX.Element => {
	const routerPush = useRouterPush();

	const onNavItemClick = async (navItem: HeaderNavItem): Promise<void> => {
		if (navItem.href) {
			await routerPush(navItem.href, navItem.queryParams);
		}
	};

	return (
		<Wrapper $isOpaque isBannerVisible={false}>
			<NavItems isOpaque onNavItemClick={onNavItemClick} />
		</Wrapper>
	);
};

export default Header;
