import { PropsWithChildren } from 'react';

import { Hr } from '@calm-web/design-system';
import { ModalProps } from '@calm-web/design-system/lib/components/Modal/types';

import FullScreenDialog from '../../full-screen-dialog';
import { DividerContainer, Title } from './styles';

type Props = ModalProps & {
	title: string;
};

const InfoDialog = ({ children, title, ...modalProps }: PropsWithChildren<Props>): JSX.Element => (
	<FullScreenDialog {...modalProps} aria-label={title}>
		<Title>{title}</Title>
		<DividerContainer>
			<Hr opacity={0.1} />
		</DividerContainer>
		{children}
	</FullScreenDialog>
);

export default InfoDialog;
