import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

export const Wrapper = styled.main`
	background: ${palette('gray0')};
	position: relative;
`;
export const Content = styled.div`
	max-width: ${theme('breakpoints.maxWidth')}px;
	margin: 0 auto;
	padding: 6rem 0;
`;
