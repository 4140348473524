/* eslint-disable max-len */

import { defineMessages } from 'react-intl';

/* ----------------- HEADER MESSAGING ----------------- */

const headerMessages = defineMessages({
	sleep: {
		id: 'nav.sleep',
		defaultMessage: 'Sleep',
		description: 'Link that takes user to sleep page',
	},
	stressAnxiety: {
		id: 'nav.stressAnxiety',
		defaultMessage: 'Stress & Anxiety',
		description: 'Link that takes user to stress page',
	},
	mindfulness: {
		id: 'nav.mindfulness',
		defaultMessage: 'Mindfulness',
		description: 'Link that takes user to mindfulness page',
	},
	screening: {
		id: 'nav.screening',
		defaultMessage: 'Screening',
		description: 'Link that takes user to Screening page',
	},
	calmBusiness: {
		id: 'nav.calmBusiness',
		defaultMessage: 'Calm Business',
		description: 'Link that takes user to Calm Business page',
	},
	calmHealth: {
		id: 'nav.calmHealth',
		defaultMessage: 'Calm Health',
		description: 'Link that takes user to Calm Health page',
	},
});

const messages = {
	...headerMessages,
};

export default messages;
