import styled from 'styled-components';
import { theme } from 'styled-tools';

import { Text, minWidth } from '@calm-web/design-system';
import { MedicalPrivacy, Time } from '@calm-web/icons';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: ${theme('breakpoints.maxWidth')}px;
	margin: 0 auto;
	justify-content: center;

	${minWidth('tablet')} {
		flex-direction: row;
	}
`;

export const Item = styled.div`
	background: white;
	padding: 2rem;
	border-radius: 12px;
	flex: 1;

	&:last-of-type {
		margin-top: 1rem;
	}

	display: flex;
	flex-direction: column;
	align-items: center;

	${minWidth('tablet')} {
		&:first-of-type {
			margin-right: 0.75rem;
		}
		&:last-of-type {
			margin-left: 0.75rem;
		}
		&:last-of-type {
			margin-top: 0;
		}
	}
`;

export const ItemTitle = styled(Text).attrs({
	align: 'center',
	weight: 500,
})`
	color: #1a1a2c;
	margin-top: 1rem;
	font-size: 20px;
`;

export const ItemBody = styled(Text).attrs({
	align: 'center',
})`
	color: #1a1a2cb2;
`;

export const IconContainer = styled.div`
	background-color: #e5f3ff;
	border-radius: 50%;
	height: 64px;
	width: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TimeIcon = styled(Time)`
	width: 38px;
	height: 38px;
`;

export const MedicalPrivacyIcon = styled(MedicalPrivacy)`
	width: 38px;
	height: 38px;
`;
