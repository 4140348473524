import { useIntl } from 'react-intl';

import { infoItems } from './data';
import { Wrapper, Item, ItemTitle, ItemBody, IconContainer } from './styles';

const InfoPods = (): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		<Wrapper>
			{infoItems.map(({ title, body, icon }) => (
				<Item key={title.id}>
					<IconContainer>{icon}</IconContainer>
					<ItemTitle>{formatMessage(title)}</ItemTitle>
					<ItemBody>{formatMessage(body)}</ItemBody>
				</Item>
			))}
		</Wrapper>
	);
};

export default InfoPods;
