import messages from './messages';
import { FooterNavSection, SocialMediaItem } from './types';

export const getFooterNavItems = (): FooterNavSection[] => [
	{
		title: messages.company,
		items: [
			{
				href: `https://www.calm.com/blog/about`,
				name: messages.about,
			},
			{
				href: `https://www.calm.com/careers`,
				name: messages.careers,
			},
			{
				href: `https://www.calm.com/blog/press`,
				name: messages.press,
			},
			{
				href: `https://www.calm.com/mindfulness-tips`,
				name: messages.blog,
			},
			{
				href: `https://www.calm.com/blog/tamara-levitt`,
				name: messages.instructor,
			},
			{
				href: `https://www.calm.com/science`,
				name: messages.science,
			},
		],
	},
	{
		title: messages.offers,
		items: [
			{
				href: `https://www.calm.com/gift`,
				name: messages.gift,
			},
			{
				href: `https://www.calm.com/redeem`,
				name: messages.redeem,
			},
			{
				href: '/family',
				name: messages.familyPlan,
			},
			{
				href: 'https://calmhealth.com/',
				name: messages.calmHealth,
				newWindow: true,
				isEnglishOnly: true,
			},
			{
				href: `https://business.calm.com/?utm_campaign=calmdotcom_for_business_footer`,
				name: messages.business,
			},
		],
	},
	{
		title: messages.help,
		items: [
			{
				href: `https://www.calm.com/faq/web`,
				name: messages.faq,
				newWindow: true,
			},
			{
				href: 'https://support.calm.com/hc/en-us/requests/new',
				name: messages.contact,
				newWindow: true,
			},
			{
				href: `https://www.calm.com/terms`,
				name: messages.terms,
			},
			{
				href: '/privacy-policy',
				name: messages.privacy,
			},
			{
				href: `/privacy-policy#information-for-california-residents`,
				name: messages.californiaPrivacyNotice,
			},
			{
				href: 'https://www.calm.com/optout',
				name: messages.privacyChoices,
				isPrivacyChoices: true,
			},
			{
				href: `https://www.calm.com/cookies`,
				name: messages.cookies,
			},
			{
				href: `https://www.calm.com/en/accessibility-statement`,
				name: messages.accessibilityStatement,
			},
		],
	},
];

export const socials: SocialMediaItem[] = [
	{
		name: 'Facebook',
		href: `https://www.calm.com/facebook`,
	},
	{
		name: 'Instagram',
		href: `https://www.calm.com/instagram`,
	},
	{
		name: 'Twitter',
		href: `https://www.calm.com/twitter`,
	},
];
