import { useRouter } from 'next/router';
import qs from 'query-string';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
	SocialFacebook as FacebookIcon,
	SocialInstagram as InstagramIcon,
	SocialTwitter as TwitterIcon,
	PrivacyOptions,
} from '@calm-web/icons';

import { getFooterNavItems, socials } from './data';
import messages from './messages';
import {
	Copyright,
	FooterItem,
	FooterColumn,
	FooterHeader,
	FooterList,
	SocialIcon,
	SocialIconsColumn,
	TiCLink,
	Wrapper,
	ColumnGrid,
} from './styles';
import { FooterNavItem, FooterProps, SocialMediaItem } from './types';

/**
 *
 * Displays a Nav bar with various i18n links in it
 *
 */
const Footer = ({ initialTheme }: FooterProps): JSX.Element => {
	const { formatMessage } = useIntl();
	const { query, push, pathname, locale } = useRouter();
	const queryString = Object.keys(query).length ? `?${qs.stringify(query)}` : '';

	const onNavItemClick = async (item: FooterNavItem): Promise<void> => {
		const { newWindow, href } = item;

		if (!href) {
			return;
		}

		if (newWindow) {
			window.open(href);
		} else {
			await push(href);
		}
	};

	const getSocialIcon = (socialName: string): JSX.Element | null => {
		switch (socialName) {
			case 'Facebook':
				return <FacebookIcon />;
			case 'Instagram':
				return <InstagramIcon />;
			case 'Twitter':
				return <TwitterIcon />;
			default:
				return null;
		}
	};

	const handleSocialIconInteraction = (socialItem: SocialMediaItem): Promise<void> => {
		return onNavItemClick({
			name: { defaultMessage: socialItem.name },
			newWindow: true,
			href: socialItem.href,
		});
	};

	const footerNavItems = useMemo(() => {
		return getFooterNavItems();
	}, []);

	const footerTheme = 'dark';
	const TiCItem = {
		href: 'https://www.cigna.com/legal/compliance/machine-readable-files',
		name: { defaultMessage: 'TiC MRF' },
	};

	return (
		<Wrapper $theme={footerTheme}>
			<ColumnGrid allowOverflow gridEl="ul">
				{footerNavItems.map(section => (
					<FooterColumn key={section.title.defaultMessage}>
						<FooterHeader>{formatMessage(section.title)}</FooterHeader>
						<FooterList>
							{section.items
								.filter(item => (item.isEnglishOnly ? locale === 'en' : true))
								.map(item => {
									const href = `${item.href}${queryString}`;
									const target = item.newWindow ? '_blank' : '';
									return (
										<li key={item.href}>
											<FooterItem
												onClick={(): Promise<void> => onNavItemClick(item)}
												href={href}
												target={target}
											>
												{formatMessage(item.name)}
												{item.isPrivacyChoices ? (
													<PrivacyOptions fill="white" height={14} width={40} />
												) : null}
											</FooterItem>
										</li>
									);
								})}
						</FooterList>
					</FooterColumn>
				))}
				<li>
					<SocialIconsColumn $theme={footerTheme}>
						{socials.map(socialItem => (
							<li key={`social_${socialItem.name}`}>
								<SocialIcon
									aria-label={formatMessage(messages.socialIconAriaLabel, { socialMedia: socialItem.name })}
									tabIndex={0}
									role="link"
									onKeyDown={e => e.key === 'Enter' && handleSocialIconInteraction(socialItem)}
									onClick={() => handleSocialIconInteraction(socialItem)}
								>
									{getSocialIcon(socialItem.name)}
								</SocialIcon>
							</li>
						))}
					</SocialIconsColumn>
				</li>
			</ColumnGrid>
			<Copyright $theme={footerTheme}>
				{formatMessage(messages.copyright, {
					year: new Date().getFullYear(),
				})}
			</Copyright>
			{pathname === '/careers' && (
				<TiCLink $theme={footerTheme}>
					{/* eslint-disable @calm/react-intl/missing-formatted-message */}
					<FooterItem onClick={() => onNavItemClick(TiCItem)} href={TiCItem.href}>
						TiC MRF
					</FooterItem>
					{/* eslint-enable @calm/react-intl/missing-formatted-message */}
				</TiCLink>
			)}
		</Wrapper>
	);
};

export default Footer;
