import messages from './messages';
import { MedicalPrivacyIcon, TimeIcon } from './styles';

export const infoItems = [
	{
		title: messages.durationTitle,
		body: messages.durationBody,
		icon: <TimeIcon />,
	},
	{
		title: messages.privacyTitle,
		body: messages.privacyBody,
		icon: <MedicalPrivacyIcon />,
	},
];
