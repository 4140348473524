import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'screening.gad.title',
		defaultMessage: 'GAD-7',
		description: '',
	},
	section1Title: {
		id: 'screening.gad.section1Title',
		defaultMessage: 'What is the GAD-7 Anxiety screening?',
		description: '',
	},
	section1Body: {
		id: 'screening.gad.section1Body',
		defaultMessage:
			"The GAD-7 Anxiety screening is designed to help you understand if you might be experiencing symptoms of generalized anxiety disorder. The screening asks you seven questions about how you've been feeling over the past two weeks, particularly regarding anxiety.",
		description: '',
	},
	section2Title: {
		id: 'screening.gad.section2Title',
		defaultMessage: 'How the GAD-7 works',
		description: '',
	},
	section2Body: {
		id: 'screening.gad.section2Body',
		defaultMessage:
			"When you take the GAD-7 screening, you'll be asked if you've felt nervous, anxious, or on edge in recent days. The questions also ask if you've been worried, having trouble relaxing, or feeling so restless that it's hard to sit still. After completing the screening, your answers are scored to give you an idea of the severity of your anxiety symptoms",
		description: '',
	},
	section3Body1: {
		id: 'screening.gad.section3Body1',
		defaultMessage:
			"The GAD-7 screening is a standard questionnaire used by mental health providers that can give you an idea of how you’re doing, but it cannot by itself diagnose generalized anxiety disorder. If you're experiencing symptoms of an anxiety disorder, or your score suggests moderate or severe anxiety, seek professional advice for a full assessment and to discuss treatments or strategies to manage your anxiety.",
		description: '',
	},
	section3Body2: {
		id: 'screening.gad.section3Body2',
		defaultMessage:
			"The GAD-7 is widely used because it's quick, simple, and can give both you and your healthcare providers a snapshot of what you're experiencing.",
		description: '',
	},
});
