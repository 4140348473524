import { useIntl } from 'react-intl';

import messages from './messages';
import { SkipToMainLink } from './styles';

export const MAIN_CONTENT_ID = 'main-content';

interface Props {
	href: string;
}

const SkipToMain = ({ href }: Props): JSX.Element => {
	const { formatMessage } = useIntl();

	return (
		<SkipToMainLink href={href || `#${MAIN_CONTENT_ID}`}>
			{formatMessage(messages.skipToMainCta)}
		</SkipToMainLink>
	);
};

export default SkipToMain;
