import { useIntl } from 'react-intl';

import InfoDialog from '..';
import ScaleExplanation from '../scale-explanation';
import { phqGroups } from '../scale-explanation/data';
import { Section, SectionParagraph, SectionTitle } from '../styles';
import { InfoDialogProps } from '../types';
import messages from './messages';

const PhqInfoDialog = ({ isOpen, onClose }: InfoDialogProps): JSX.Element => {
	const { formatMessage } = useIntl();
	return (
		<InfoDialog title={formatMessage(messages.title)} isOpen={isOpen} closeModal={onClose}>
			<Section>
				<SectionTitle>{formatMessage(messages.section1Title)}</SectionTitle>

				<SectionParagraph>{formatMessage(messages.section1Body)}</SectionParagraph>
			</Section>
			<Section>
				<SectionTitle>{formatMessage(messages.section2Title)}</SectionTitle>
				<SectionParagraph> {formatMessage(messages.section2Body1)}</SectionParagraph>
				<SectionParagraph> {formatMessage(messages.section2Body2)}</SectionParagraph>
				<SectionParagraph> {formatMessage(messages.section2Body3)}</SectionParagraph>
			</Section>
			<Section>
				<ScaleExplanation groups={phqGroups} />
			</Section>
		</InfoDialog>
	);
};

export default PhqInfoDialog;
