import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'screening.narrators.title',
		defaultMessage: 'Meet our Narrators',
		description: '',
	},
	meet: {
		id: 'screening.narrators.meet',
		defaultMessage: 'Meet {name}',
		description: '',
	},
	narrator: {
		id: 'screening.narrators.narrator',
		defaultMessage: 'Narrator',
		description: '',
	},
});
