import { defineMessages } from 'react-intl';

export default defineMessages({
	durationTitle: {
		id: 'screening.info.duration.title',
		defaultMessage: 'The screening will take 1-2 minutes',
		description: '',
	},
	durationBody: {
		id: 'screening.info.duration.body',
		defaultMessage:
			'We’ll ask you a series of standard questions that were created by licensed professionals to help in screening your mental health.',
		description: '',
	},
	privacyTitle: {
		id: 'screening.info.privacy.title',
		defaultMessage: 'We value your privacy',
		description: '',
	},
	privacyBody: {
		id: 'screening.info.privacy.body',
		defaultMessage:
			'All your responses are completely confidential, and we will not share or sell your data to anyone.',
		description: '',
	},
});
