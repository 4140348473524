import messages from '@/messages/messages';

import { HeaderNavItem } from '../NavItemLink/types';

export const leftNavItems: HeaderNavItem[] = [
	{ label: messages.sleep, href: 'https://www.calm.com/sleep' },
	{ label: messages.stressAnxiety, href: 'https://www.calm.com/stress-anxiety' },
	{ label: messages.mindfulness, href: 'https://www.calm.com/mindfulness' },
];

export const rightNavItems: HeaderNavItem[] = [
	{
		label: messages.screening,
		href: '/',
		analyticsName: 'Homepage : NavHeader : Health Screening : Clicked',
	},
	{
		label: messages.calmBusiness,
		href: 'https://business.calm.com',
		queryParams: { utm_campaign: 'homepage_for_business' },
		analyticsName: 'Homepage : NavHeader : For Business : Clicked',
	},
	{
		label: messages.calmHealth,
		href: 'https://calmhealth.com/',
		analyticsName: 'Homepage : NavHeader : Calm Health : Clicked',
	},
];
