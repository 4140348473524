import { ModalProps } from '@calm-web/design-system/lib/components/Modal/types';

import { Dialog, Content, InnerContent } from './styles';

const FullScreenDialog = ({ isOpen, closeModal, children, ...modalProps }: ModalProps): JSX.Element => (
	<Dialog {...modalProps} isOpen={isOpen} closeModal={closeModal} width="Full" noPadding hasOverflowY>
		<Content>
			<InnerContent>{children}</InnerContent>
		</Content>
	</Dialog>
);
export default FullScreenDialog;
