import { GetStaticProps } from 'next/types';

import HeaderRefresh from '@/components/homepageRefresh/header/Header';
import Layout from '@/components/layout/LayoutWrapper';
import Screening from '@/components/screening';

const ScreeningPage = (): JSX.Element => {
	return (
		<Layout pageName="screening" title="Calm" showFooter>
			<HeaderRefresh />
			<Screening />
		</Layout>
	);
};
export default ScreeningPage;

export const getStaticProps: GetStaticProps = async () => {
	return {
		props: {},
		revalidate: 60 * 60 * 1, // 1 hour
	};
};
