import { useEffect, useState } from 'react';

import { EventProps, setupEventProps } from '@/utils/analytics';
import { LogEventResult, initAmplitude, logAmplitudeEvent } from '@/utils/analytics/amplitude';

const logEventAsync = async ({
	eventName,
	eventProps,
}: {
	eventName: string;
	eventProps?: EventProps;
}): Promise<LogEventResult> => {
	const hydratedEventProps = setupEventProps(eventProps ?? {});
	return logAmplitudeEvent({ eventName, eventProps: hydratedEventProps });
};

const logEvent = (args: { eventName: string; eventProps?: EventProps }): void => {
	logEventAsync(args)
		// eslint-disable-next-line no-console
		.catch(console.error);
};

export const useAnalytics = (): {
	logEvent: (args: { eventName: string; eventProps?: EventProps }) => void;
	logEventAsync: ({
		eventName,
		eventProps,
	}: {
		eventName: string;
		eventProps?: EventProps;
	}) => Promise<LogEventResult>;
	hasInitialized: boolean;
} => {
	const [hasInitialized, setHasInitialized] = useState(false);

	useEffect(() => {
		if (hasInitialized) {
			return;
		}
		initAmplitude()
			.then(() => {
				setHasInitialized(true);
			})
			// eslint-disable-next-line no-console
			.catch(console.error);
	}, [hasInitialized]);

	return { logEvent, logEventAsync, hasInitialized };
};
