import styled from 'styled-components';

import { Text } from '@calm-web/design-system';

export const Wrapper = styled.div`
	margin-top: 6rem;
	padding: 0 1rem;
`;

export const LegalText = styled(Text).attrs({ el: 'p', weight: 500, noMargin: true })`
	color: #1a1a2cb2;
	font-size: 14px;
	max-width: 1139px;
	line-height: 22px;
`;
