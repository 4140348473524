import styled from 'styled-components';

import { Text } from '@calm-web/design-system';

export const Title = styled(Text).attrs({ el: 'h1' })`
	color: #1a1a2c;
	font-size: 34px;
`;

export const DividerContainer = styled.div`
	width: 100%;
	padding: 1.5em 0 0;
`;

export const Section = styled.div`
	margin-top: 2.5em;
	width: 100%;
`;

export const SectionTitle = styled(Text).attrs({ el: 'h2', weight: 500 })`
	color: #1a1a2c;
	font-size: 24px;
	width: 100%;
`;

export const SectionParagraph = styled(Text).attrs({ el: 'p' })`
	color: #1a1a2cb2;
	font-size: 18px;
	margin-top: 0.5em;

	&:nth-of-type(n + 2) {
		margin-top: 2em;
	}
`;
