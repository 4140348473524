import messages from './messages';

export const gadGroups = [
	{ color: '#75b8e7', label: messages.minimal },
	{ color: '#77f094', label: messages.mild },
	{ color: '#f7fa81', label: messages.moderate },
	{ color: '#f07889', label: messages.severe },
];

export const phqGroups = [
	{ color: '#75b8e7', label: messages.minimal },
	{ color: '#77f094', label: messages.mild },
	{ color: '#f7fa81', label: messages.moderate },
	{ color: '#fcc067', label: messages.moderateSevere },
	{ color: '#f07889', label: messages.severe2 },
];
