import bowser from 'bowser';

import { JSONObject } from '@/utils/types';

export type EventProps = JSONObject;

interface StripTrailingCharArgs {
	str: string;
	char: string;
}

function stripTrailingChar({ str, char }: StripTrailingCharArgs): string {
	if (str.substr(-1) === char) {
		return str.substr(0, str.length - 1);
	}
	return str;
}

function getSlug(): string {
	const pathname = window?.location?.pathname ?? '';
	const stripped = stripTrailingChar({
		str: pathname,
		char: '/',
	}).toLowerCase();
	if (stripped.length === 0) return '/';
	return stripped;
}

export const setupEventProps = (eventProps: EventProps): EventProps => {
	if (typeof window === 'undefined') {
		return eventProps;
	}

	const browser = bowser.getParser(window.navigator?.userAgent || '');
	const browserInstance = browser.getBrowser();

	const slug = getSlug();

	const referrer = (() => {
		if (!document?.referrer) {
			return undefined;
		}
		if (document.referrer.indexOf('password-reset') > -1) {
			return '/password-reset'; // Don't log the reset password link
		}
		if (document.referrer.indexOf('gp/') > -1) {
			return '/gp'; // Don't log the guest pass
		}
		if (document.referrer.indexOf('manage-subscription') > -1) {
			return '/manage-subscription'; // Don't log manage subscription
		}
		return document.referrer;
	})();

	return {
		...eventProps,
		slug,
		...(referrer ? { referrer } : {}),
		...(browserInstance.name ? { browser: browserInstance.name } : {}),
		...(browserInstance.version ? { browser_version: browserInstance.version } : {}),
		service: 'web-screening',
	};
};
